<template>
    <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create Testimony</h1>
            <hr>
            <div class="row" v-if="!isLoad">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto" v-if="form.id">
                    <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <form @submit.prevent="patchMethod" class="row p-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="row p-auto">
                                    <div class="col-lg-12 mt-4">
                                        <div class="row">
                                            <div class="form-group col-md-12">
                                                <label for="">Name</label>
                                                <input type="text" class="form-control" v-model="form.name" placeholder="Insert name" required/>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Code</label>
                                                <input type="code" class="form-control" v-model="form.code" placeholder="Insert code" disabled required/>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Image</label><br>
                                                <file-upload
                                                class="btn btn-lg btn-current mr-1"
                                                @input="updateFile"
                                                @input-file="inputFile"
                                                @input-filter="fileFilter"
                                                :custom-action="fileAction"
                                                accept="image/png,image/jpg,image/svg,image/webp"
                                                ref="upload" required>
                                                <i class="fa fa-plus"></i>
                                                Upload Image
                                                </file-upload>

                                                <a v-if="form.image_url" :href=" form.image_url" target="_blank" class="btn btn-lg btn-success">Current File</a>

                                                <button type="button" class="btn btn-lg btn-danger ml-1"  v-if="!$refs.upload || $refs.upload.active" @click.prevent="$refs.upload.active = false">
                                                <i class="fa fa-stop" aria-hidden="true"></i>
                                                Stop Upload
                                                </button>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Type</label>
                                                <select class="form-control" v-model="form.type" disabled required>
                                                    <option value="" selected disabled>-- Select Payment Method Type --</option>
                                                    <option value="VA">Virtual Account</option>
                                                    <option value="E-Wallet">E-Wallet</option>
                                                    <option value="QR">QR</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-12" v-if="form.type == 'VA'">
                                                <label for="">Prefix VA</label>
                                                <input type="Text" class="form-control" v-model="form.prefix_va" disabled placeholder="Insert prefix"/>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Fee Type</label>
                                                <select class="form-control" v-model="form.type_fee" @change="form.fee = 0" required>
                                                    <option value="" selected disabled>-- Select Fee Type --</option>
                                                    <option value="AMOUNT">Amount</option>
                                                    <option value="PERCENT">Percent</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Fee {{ form.type_fee == 'AMOUNT' ? 'Amount' : 'Percent' }}</label>
                                                <input v-if="form.type_fee == 'AMOUNT'" type="number" class="form-control" v-model="form.fee" placeholder="Insert fee" required/>
                                                <input v-else type="number" class="form-control" v-model="form.fee" placeholder="Insert fee" max="100" required/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-right">
                                        <button type="submit" class="btn btn-current">
                                        Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-12 text-center py-3" v-else>
                    <img :src="'/images/empty.png'" alt="" width="300">
                    <h1 class="text-muted">Data don't exist</h1>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
import axios from 'axios'
import FileUpload from 'vue-upload-component'
export default {
    components:{
        FileUpload
    },
    data(){
        return {
            idMethod: this.$route.params.idMethod,
            isLoad: true,
            media : process.env.VUE_APP_URL_CLOUD,
            form : {
                name: '',
                code: '',
                image_url: '',
                prefix_va: '',
                type: '',
                fee: '',
                type_fee: '',
            },
            files: [],
        }
    },
    created() {
        this.getMethod()
    },
    methods: {
        async getMethod(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/payment-method/detail?id=${this.idMethod}`, {
                headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.form = res.data.data
                }
            }).catch((err) => console.error(err.response)).finally(() => this.isLoad = false)
        },
        async patchMethod() {
            var data = this.form
            await axios.patch(`${process.env.VUE_APP_URL_API}/admin/setting/payment-method`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Payment Method',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'SettingsPaymentMethodAdmin' })
            }).catch(err => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Payment Method',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        updateFile(value) {
            this.files = value
        },
        async fileAction(file) {
            return this.postFile(file)
        },
        async inputFile(newFile, oldFile) {
            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
        },
        fileFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(png|jpg|svg|webp)$/i.test(newFile.name)) {
                    return prevent()
                }
            }
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir', 'payment-methods');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.form.image_url = res.data.path
                return res.data
            })
        },
        async deleteFile(file){
            let data = new FormData();
            data.append('path', file.response.path);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/delete`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.files = []
                this.image_url = ''
                return res.data
            })
        },
    }
}
</script>